<template>
  <div
    class="main-wrapper"
    :class="{ mobile: mobile }"
    v-resizeObserver="{
      openResize: true,
      handler: observerCallBack
    }"
  >
    <div class="card">
      <div class="main-title">
        <h1 class="main-text">{{ $t('propTrade.payout') }}</h1>
      </div>
      <el-form class="form-box" :model="form" :rules="formRules" ref="form" :class="{ mobile: mobile }">
        <div class="payment-method" :class="{ mobile: mobile }">
          <el-form-item prop="paymentMethod">
            <div class="title-box">
              <h4 class="title">
                {{ $t('propTrade.payoutPage.paymentChannel') }}
              </h4>
            </div>

            <div class="payment-method-item">
              <el-form-item>
                <div class="sub-line" v-if="info.refundAmount > 0">
                  <h4 class="sub-title">
                    {{ $t('propTrade.payoutPage.refund') }} - {{ account.currency }}
                    {{ info.refundAmount | fixedDecimal(2) }}
                  </h4>
                  <div class="choice">
                    <span v-if="hasCreditCard">{{ $t('common.withdrawChannel.creditcard') }}</span>
                    <span v-else
                      >{{ firstLetterUpperCase(form.paymentMethod) }} -
                      {{
                        form.paymentMethod === 'crypto'
                          ? langTranslate('deposit.default.channels.', form.crypto)
                          : langTranslate('deposit.default.channels.', form.ewallet)
                      }}</span
                    >
                  </div>
                </div>
              </el-form-item>
              <el-form-item v-if="hasCreditCard">
                <el-row :gutter="30">
                  <el-col :xs="24" :sm="12">
                    <el-form-item :label="$t('common.field.cardholderName')" prop="">
                      <el-input v-model="creditCard.cardHolderName" class="plain-input" :disabled="true" />
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <el-form-item :label="$t('common.field.ccNum')" prop="">
                      <el-input v-model="creditCard.cardNumber" class="plain-input" :disabled="true" />
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <el-form-item :label="$t('common.field.ccExpireDate')" prop="">
                      <el-input v-model="creditCard.cardExpiryDate" class="plain-input" :disabled="true" />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </div>

            <div class="payment-method-item" v-for="itme in calPayoutMethodList" :key="itme.key">
              <div
                v-if="
                  (itme.key === 'crypto' && form.paymentMethod === 'crypto') ||
                  (itme.key === 'ewallet' && form.paymentMethod === 'ewallet')
                "
              >
                <div class="sub-line">
                  <h4 class="sub-title">
                    {{ $t('propTrade.payoutPage.profitSplit') }} - {{ account.currency }}
                    {{ info.profitSplit | fixedDecimal(2) }}
                  </h4>
                  <div class="choice">
                    <span
                      >{{ firstLetterUpperCase(form.paymentMethod) }} -
                      {{
                        form.paymentMethod === 'crypto'
                          ? langTranslate('deposit.default.channels.', form.crypto)
                          : langTranslate('deposit.default.channels.', form.ewallet)
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <el-radio-group v-model="form.paymentMethod" @input="setPaymentDetailDefault" data-testid="paymentMethod">
                <el-radio :label="itme.key" :data-testid="itme.key">
                  {{ itme.name }}
                </el-radio>
              </el-radio-group>

              <el-form-item prop="crypto" v-if="itme.key === 'crypto' && form.paymentMethod === 'crypto'">
                <div class="payout-radio">
                  <el-radio-group v-model="form.crypto">
                    <PayoutRadio
                      v-for="(payment, index) in cryptoList"
                      :key="`ptCrypto${index}`"
                      :payout="payment"
                      @changePayoutDetail="changeCryptoPayoutDetail"
                    ></PayoutRadio>
                  </el-radio-group>
                  <div id="withDraw" class="withdraw-container">
                    <component
                      :is="currentComponent"
                      :accountNumber="routerAccountNumber"
                      :amount="info.profitSplit"
                      :currency="account.currency"
                      :withdrawalType="cryptoSelectWithdrawType"
                      :followerResults.sync="followerResults"
                      :key="cryptoSelectWithdrawType"
                      ref="cryptoComponent"
                    ></component>
                  </div>
                </div>
              </el-form-item>
              <el-form-item prop="ewallet" v-if="itme.key === 'ewallet' && form.paymentMethod === 'ewallet'">
                <div class="payout-radio">
                  <el-radio-group v-model="form.ewallet">
                    <PayoutRadio
                      v-for="(payout, index) in ewalletList"
                      :key="`ptEwallet${index}`"
                      :payout="payout"
                      :showName="false"
                      @changePayoutDetail="changeCPSPayoutDetail"
                    ></PayoutRadio>
                  </el-radio-group>
                </div>
                <div id="withDraw" class="withdraw-container">
                  <PtCpsWithdraw
                    :accountNumber="numberRouterAccountId"
                    :amount="info.profitSplit"
                    :withdrawalType="cpsWithdrawType"
                    :percentageFee="percentageFee"
                    :showFee="showFee"
                    :currency="account.currency"
                    :withdrawalPayment="$store.state.cps.withdrawalPayment"
                    ref="cpsComponent"
                  ></PtCpsWithdraw>
                </div>
              </el-form-item>
            </div>
          </el-form-item>
        </div>

        <div class="payment-detail-box" :class="{ mobile: mobile }">
          <div class="title-box right-title">
            <h4 class="title">
              {{ $t('propTrade.payoutPage.summary') }}
            </h4>
          </div>

          <div class="detail">
            <ul class="detail-list">
              <li>
                <span class="lable">{{ $t('propTrade.payoutPage.sessionNumber') }} :</span>
                <span class="value">{{ account.sessionNumber }}</span>
              </li>
              <li>
                <span class="lable">{{ $t('propTrade.payoutPage.currency') }} :</span>
                <span class="value">{{ account.currency }}</span>
              </li>
            </ul>
            <ul class="detail-list">
              <li>
                <span class="lable"
                  >{{ $t('propTrade.payoutPage.profitsplit', { percent: `${info.profitSplitPercent}%` }) }} :</span
                >
                <span class="value">{{ account.currency }} {{ info.profitSplit | fixedDecimal(2) }}</span>
              </li>
              <li>
                <span class="lable">{{ $t('propTrade.payoutPage.registrationFeeRefund') }} :</span>
                <span class="value">{{ account.currency }} {{ info.refundAmount | fixedDecimal(2) }}</span>
              </li>
              <li v-if="info.profitSplitFee > 0">
                <span class="lable">
                  {{
                    $t('propTrade.payoutPage.profitSplitFee', {
                      channel:
                        form.paymentMethod === 'crypto'
                          ? langTranslate('deposit.default.channels.', form.crypto)
                          : langTranslate('deposit.default.channels.', form.ewallet)
                    })
                  }}
                  :
                </span>
                <span class="value minus">-{{ account.currency }} {{ info.profitSplitFee | fixedDecimal(2) }}</span>
              </li>
              <li v-if="info.refundAmountFee > 0">
                <span class="lable">{{ $t('propTrade.payoutPage.refundAmountFee') }} :</span>
                <span class="value minus">-{{ account.currency }} {{ info.refundAmountFee | fixedDecimal(2) }}</span>
              </li>
              <div class="hr"></div>
              <li>
                <span class="lable bold">
                  <span v-html="$t('propTrade.payoutPage.totalEligiblePayoutAmount')"></span>
                  <InfoPopover popoverWidth="220">
                    <template v-slot:popoverContent>
                      {{ $t('propTrade.payoutPage.totalEligiblePayoutAmountInfo') }}
                    </template>
                  </InfoPopover>
                </span>
                <span class="value bold">{{ account.currency }} {{ info.payoutAmount | fixedDecimal(2) }}</span>
              </li>
            </ul>
          </div>
          <div class="btn-box">
            <div class="back-button">
              <Button
                buttonType="default"
                customClasses="action-button"
                outline
                dataTestId="back"
                @click="goBackToChooseAccount"
              >
                <div>{{ $t('propTrade.button.back') }}</div>
              </Button>
            </div>
            <div class="confirm-button">
              <Button buttonType="default" customClasses="action-button" dataTestId="confirm" @click="submit">
                <div>{{ $t('propTrade.button.payout') }}</div>
              </Button>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <InfoDialog :visible.sync="showSuccessDialog" infoType="dialog_true" class="reset-info" @closCbDialog="closeDialog">
      <div class="info-container">
        <div>{{ $t('propTrade.payoutPage.payoutSuccessDialog') }}</div>
      </div>
      <template #actions>
        <Button buttonType="default" @click="showSuccessDialog = false">
          {{ $t('common.button.ok') }}
        </Button>
      </template>
    </InfoDialog>

    <InfoDialog
      :visible.sync="showErrorDialog"
      infoType="fail"
      class="reset-info"
      :title="$t('propTrade.payoutPage.payoutRequestIsUnsuccessful')"
      @closCbDialog="closeDialog"
    >
      <div class="info-container">
        <div class="desc">{{ $t('propTrade.payoutPage.payoutRequestIsUnsuccessfulDesc') }}</div>
        <div class="desc">{{ $t('propTrade.payoutPage.payoutRequestIsUnsuccessfulDesc2') }}</div>
        <div class="desc">{{ $t('propTrade.payoutPage.payoutRequestIsUnsuccessfulDesc3') }}</div>
      </div>
      <template #actions>
        <Button buttonType="default" @click="showErrorDialog = false"> {{ $t('common.button.ok') }} </Button>
      </template>
    </InfoDialog>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import TextInput from '@/components/common/TextInput.vue';
import InfoPopover from '@/components/common/InfoPopover';
import PaymentRadio from '@/components/propTrading/components/createAccount/PaymentRadio.vue';
import PayoutRadio from '@/components/propTrading/components/payout/PayoutRadio.vue';
import cpsWithdrawlalMixin from '@/mixins/page/propTrade/cpsWithdrawal';
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
import { ACCOUNT_TYPE_REV, CRYPTO_USDT_TRC20_FAKE_ID, PAYOUT_METHOD } from '@/constants/propTrading';
import propTradeCommonFilters from '@/mixins/page/propTrade/commonFilters';
import { apiPayoutInfo, apiGetAccountMetrics, apiPaymentInfo, apiPropTradingPayout } from '@/resource';
import mixin from '@/mixins';
import { nextTick } from 'vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import { isSuccess } from '@/util/restApi';
import { mapState } from 'vuex';
import { statusConfig } from '@/constants/status';
// withdraws
import PtBitcoinWithdraw from '@/components/propTrading/components/payout/withdraw/PtBitcoinWithdraw.vue';
import PtCryptoCpsWithdraw from '@/components/propTrading/components/payout/withdraw/PtCryptoCpsWithdraw.vue';
import PtUsdtWithdraw from '@/components/propTrading/components/payout/withdraw/PtUsdtWithdraw.vue';
import PtCpsWithdraw from '@/components/propTrading/components/payout/withdraw/PtCpsWithdraw.vue';

const withdrawalTypeMapping = {
  [CRYPTO_USDT_TRC20_FAKE_ID]: '36'
};

export default {
  name: 'Payout',
  components: {
    Button,
    TextInput,
    InfoPopover,
    PaymentRadio,
    PayoutRadio,
    InfoDialog,
    PtBitcoinWithdraw,
    PtCryptoCpsWithdraw,
    PtUsdtWithdraw,
    PtCpsWithdraw
  },
  mixins: [cpsWithdrawlalMixin, cpsMixin, propTradeCommonFilters, mixin],
  data() {
    return {
      showErrorDialog: false,
      showSuccessDialog: false,
      withdrawalTypesMap: {
        34: {
          component: PtBitcoinWithdraw
        },
        36: {
          component: PtUsdtWithdraw
        },
        [CRYPTO_USDT_TRC20_FAKE_ID]: {
          component: PtUsdtWithdraw
        },
        80: {
          component: PtCryptoCpsWithdraw
        },
        81: {
          component: PtCryptoCpsWithdraw
        },
        86: {
          component: PtCpsWithdraw
        },
        87: {
          component: PtCpsWithdraw
        }
      },
      followerResults: [],
      currentComponent: PtBitcoinWithdraw,
      ewalletCurrentComponent: PtCpsWithdraw,
      cryptoSelectWithdrawType: null,
      cpsWithdrawType: null,
      isShowSticPay: false,
      percentageFee: 0,
      showFee: false,
      creditCard: {
        cardHolderName: '',
        cardNumber: '',
        cardExpiryDate: ''
      },
      account: {
        accountNumber: '',
        currency: '',
        sessionNumber: ''
      },
      info: {
        balance: 0,
        profitSplitPercent: 0,
        refundAmount: 0,
        profitSplit: 0,
        payoutAmount: 0,
        profitSplitFee: 0,
        refundAmountFee: 0
      },
      mainWrapperWidth: 0,
      ACCOUNT_TYPE_REV,
      form: {
        paymentMethod: '',
        crypto: null,
        ewallet: null,
        withdrawalType: -1
      },
      formRules: {
        paymentMethod: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('propTrade.createAccount.billingDetail.subTitle')
            }),
            trigger: 'change'
          }
        ]
      },
      paymentDetail: {},
      avialableCurrencies: ['EUR', 'USD', 'GBP', 'CAD']
    };
  },
  computed: {
    ...mapState('register', ['poaAuditStatus']),
    ...mapState('propTrading', {
      hasPropTradingSession: 'session',
      hasPropTradingAccount: 'account'
    }),
    lang() {
      return this.$store.state.common.lang;
    },
    hasCreditCard() {
      return !!this.creditCard.cardNumber;
    },
    hasRefund() {
      return this.info.refundAmount > 0;
    },
    mobile() {
      let mobileLength = 0;
      switch (this.lang) {
        case 'fr_FR':
        case 'pt':
          mobileLength = 1110;
          break;
        case 'es':
          mobileLength = 1200;
          break;
        default:
          mobileLength = 1162;
          break;
      }
      return this.mainWrapperWidth <= mobileLength;
    },
    calPayoutMethodList() {
      const payoutMethodList = [
        {
          name: this.$t('propTrade.createAccount.billingDetail.crypto'),
          key: PAYOUT_METHOD.CRYPTO
        }
      ];

      if (this.displayEwalletList) {
        payoutMethodList.push({
          name: this.$t('propTrade.createAccount.billingDetail.ewallet'),
          key: PAYOUT_METHOD.EWALLET
        });
      }

      return payoutMethodList;
    },
    cryptoList() {
      return this.cryptoData;
    },
    ewalletList() {
      return this.cpsData.filter(
        payout => payout.id == this.sticPayId || payout?.cps?.currency_code === this.account.currency
      );
    },
    displayEwalletList() {
      return !!this.ewalletList.length;
    },
    routerAccountNumber() {
      return this.$route.params.accountNumber;
    },
    numberRouterAccountId() {
      return Number(this.$route.params.accountId);
    }
  },
  watch: {
    ewalletList(value) {
      if (value?.length) {
        this.cpsWithdrawType = value[0].id;
        this.form.ewallet = value[0].nameKey;
        this.$store.dispatch('cps/actionSetWithdrawalPayment', value[0]?.cps);
      }
    },
    async 'form.withdrawalType'() {
      this.showFee = false;
      try {
        const paymentInfo = await apiPaymentInfo(
          withdrawalTypeMapping[this.form.withdrawalType] ?? this.form.withdrawalType
        );
        this.percentageFee = paymentInfo?.data?.percentage_fee;
        this.showFee = paymentInfo?.data?.show_hint;

        const payoutInfo = await this.fetchPayoutInfo();

        if (!payoutInfo) {
          this.$router.replace({ name: 'propTrading' });
          return;
        }

        this.info.balance = payoutInfo.balance;
        this.info.profitSplitPercent = payoutInfo.profitSplitPercent;
        this.info.refundAmount = payoutInfo.refundAmount;
        this.info.profitSplit = payoutInfo.profitSplit;
        this.info.payoutAmount = payoutInfo.payoutAmount;
        this.info.profitSplitFee = payoutInfo.profitSplitFee;
        this.info.refundAmountFee = payoutInfo.refundAmountFee;
        this.creditCard.cardHolderName = payoutInfo.creditCardInfo?.nameOnCard ?? '';
        this.creditCard.cardNumber =
          payoutInfo.creditCardInfo?.cardFirstSixDigits && payoutInfo.creditCardInfo?.cardLastFourDigits
            ? payoutInfo.creditCardInfo.cardFirstSixDigits + '** ****' + payoutInfo.creditCardInfo.cardLastFourDigits
            : '';
        this.creditCard.cardExpiryDate =
          (payoutInfo.creditCardInfo?.expMonth ?? '') + '/' + (payoutInfo.creditCardInfo?.expYear ?? '');
      } catch (error) {}
    },
    async 'form.paymentMethod'(value) {
      if (value === 'crypto') {
        this.changeCryptoPayoutDetail(this.cryptoList?.[0]);
      } else {
        this.changeCPSPayoutDetail(this.ewalletList[0]);
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const accountMetrics = await this.getAccountMetrics();

      // if has no pt accounts or sessions (dashboard will not show) => direct to home page
      if (this.poaAuditStatus !== statusConfig.completed || !accountMetrics?.payout) {
        let targetPage = !this.hasPropTradingSession && !this.hasPropTradingAccount ? 'home' : 'propTrading';
        this.$router.push({ name: targetPage });
        return;
      }

      this.account.currency = accountMetrics.currency;
      this.account.accountNumber = accountMetrics.accountNumber;
      this.account.sessionNumber = accountMetrics.sessionNumber;

      await this.fetchPropTradingWithdrawalMethod(this.routerAccountNumber);
      await nextTick();

      this.form.crypto = this.cryptoList?.[0]?.label;
      this.cryptoSelectWithdrawType = this.cryptoList?.[0]?.id;
      this.form.withdrawalType = this.cryptoList?.[0]?.id;
      this.form.paymentMethod = 'crypto';
    },
    firstLetterUpperCase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    closeDialog() {
      this.$router.replace({ name: 'propTrading' });
    },
    async fetchPayoutInfo() {
      const result = await apiPayoutInfo(this.routerAccountNumber, this.form.withdrawalType);

      if (result.data.code !== 0) {
        return null;
      }

      if (result.data.data.respCodeEnum !== 'SUCCESS') {
        return null;
      }

      return result.data.data;
    },
    async getAccountMetrics() {
      const result = await apiGetAccountMetrics(this.routerAccountNumber);

      if (result.data.code === 0) {
        return result.data.data;
      }

      return null;
    },
    observerCallBack(width) {
      this.mainWrapperWidth = width;
    },
    goBackToChooseAccount() {
      this.$router.replace({ name: 'propTrading' });
    },
    async submit() {
      let childRef = null;

      if (this.form.paymentMethod === PAYOUT_METHOD.CRYPTO) {
        childRef = this.$refs.cryptoComponent[0];
      } else {
        childRef = this.$refs.cpsComponent[0];
      }

      const target = childRef.$refs['TransferFormWrapper'];
      const targetForm = childRef.$refs['TransferFormWrapper'].$refs['TransferForm'];

      try {
        const childFormValidate = await targetForm.validate();
        if (!childFormValidate) {
          return;
        }
      } catch (error) {
        return;
      }

      let childFormData = await target.getFormData();
      const { paymentMethodCode, mandatory, attachVariables, accountNumber, bankAccountName, importantNotes } =
        childFormData;

      childFormData = {
        ...childFormData,
        thirdPaymentCode: paymentMethodCode ?? '',
        thirdMandatory: mandatory ?? '',
        thirdAttachVariable: attachVariables ?? '',
        paymentAccountNumber: accountNumber ?? '',
        paymentAccountName: bankAccountName ?? '',
        paymentNotes: importantNotes ?? ''
      };

      /*
      REFUND(0,"Refund"),
      PROFIT(1,"Profit Split");
      */
      const requestBody = [];

      if (this.info.refundAmount > 0) {
        let refundData = {
          currency: this.account.currency,
          payoutType: 0,
          paymentType: withdrawalTypeMapping[this.form.withdrawalType]
            ? withdrawalTypeMapping[this.form.withdrawalType]
            : this.form.withdrawalType
        };
        if (!this.hasCreditCard) {
          refundData = { ...refundData, ...childFormData, accountNumber: this.routerAccountNumber };
        } else {
          refundData = { ...refundData, paymentType: 1, accountNumber: this.routerAccountNumber };
        }
        requestBody.push(refundData);
      }

      requestBody.push({
        currency: this.account.currency,
        payoutType: 1,
        paymentType: withdrawalTypeMapping[this.form.withdrawalType]
          ? withdrawalTypeMapping[this.form.withdrawalType]
          : this.form.withdrawalType,
        ...childFormData,
        accountNumber: this.routerAccountNumber
      });

      const result = await apiPropTradingPayout(requestBody, this.token);

      if (isSuccess(result.data)) {
        this.showSuccessDialog = true;
        return;
      }
      if (result.data.code == 1506) {
        this.showErrorDialog = true;
      }
    },
    changeCryptoPayoutDetail(data) {
      this.cryptoSelectWithdrawType = data.id;
      this.currentComponent = this.withdrawalTypesMap[this.cryptoSelectWithdrawType].component;
      if (this.cryptoCps.includes(data.id)) {
        this.changeCPSPayoutDetail(data);
      } else {
        this.form.crypto = data?.label;
        this.form.withdrawalType = this.cryptoSelectWithdrawType;
      }
    },
    async changeCPSPayoutDetail(data) {
      this.form.ewallet = data?.label;
      if (data.id === this.sticPayId) {
        this.isShowSticPay = true;
        this.form.withdrawalType = this.sticPayId;
      } else {
        this.isShowSticPay = false;
        this.cpsWithdrawType = data.id;
        this.form.withdrawalType = this.cpsWithdrawType;
        await this.$store.dispatch('cps/actionSetWithdrawalPayment', data.cps);
      }
      await nextTick();

      // clear other withdraw channels
      if (this.$refs.cpsComponent)
        this.$refs.cpsComponent[0].$refs['TransferFormWrapper'].$refs['TransferForm'].clearValidate();
    },
    setPaymentDetailDefault(value) {
      if (value === PAYOUT_METHOD.CRYPTO) {
        this.form.withdrawalType = this.cryptoSelectWithdrawType;
      } else {
        this.form.withdrawalType = this.cpsWithdrawType;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/withdrawFunds.scss';
.main-wrapper {
  padding: 48px 64px;
  max-width: 1440px;
  margin: auto;

  &.mobile {
    .card {
      padding: 0;
    }

    .main-text {
      margin-left: -48px;
      margin-right: -48px;
    }
  }
}

.main-title {
  margin-bottom: 24px;
}
.main-text {
  font-weight: 900;
  font-size: 22px;
  line-height: 34px;
}

.card {
  background-color: unset;
}

.el-form-item {
  margin-bottom: 0;
}

.title-box {
  font-size: 18px;
  font-weight: bold;
  @include rtl-sass-value(text-align, left, right);
  color: $black;
  margin-bottom: 6px;
  width: 60%;
  line-height: 1.2;
  position: relative;
  z-index: 1;
  &.right-title {
    margin-bottom: 30px;
  }

  .title {
    &.small {
      font-size: 14px;
      font-weight: 900;
      color: $pug-black;
    }
  }
  .sub-title {
    font-weight: 400;
    font-size: 14px;
    color: $pug-black;
  }
}

.form-box {
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 40px;
  gap: 40px;
  &.mobile {
    margin-left: -48px;
    margin-right: -48px;
    flex-wrap: wrap;
    gap: 0;
    padding: 20px;
  }
}
/deep/ {
  .el-radio {
    color: $black;
  }
  .el-radio__inner {
    width: 16px;
    height: 16px;
  }
  .el-radio__input.is-checked .el-radio__inner {
    content: url(~@/assets/images/openAccount/radio02.png);
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $black;
  }

  .el-input.is-disabled .el-input__inner {
    border: none;
    border-radius: 0;
    border-bottom: solid 1px #5b5b5b;
  }
}

/deep/.payout-radio {
  .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -2px;
  }
  .el-radio-button {
    display: block;
    margin-right: 0;
    margin-bottom: 16px;
    width: 100%;
  }

  .el-radio-button__inner {
    width: 100%;
    border: 1px solid $pug-pray-border;
    border-radius: 6px;
    padding: 0;
  }
  &-item-box {
    width: 100%;
    height: 56px;
    padding: 12px 40px;
    text-align: center;
  }
  .payout-img {
    height: 32px;
    display: inline-block;
    img {
      height: 100%;
    }
  }
  .payout-name {
    font-size: 14px;
    color: $black;
    width: calc(100% - 32px);
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: $pug-blue-radio-button-active-bg;
    border: 1px solid $pug-blue;
    box-shadow: none;
    .payout-name {
      color: $pug-blue;
      font-weight: bold;
    }
  }
}
.payment-method {
  width: 57.8%;
  max-width: 628px;
  border-right: 1px solid #dedede;
  &.mobile {
    width: 100%;
    max-width: unset;
    border: none;
  }
  &-item {
    display: block;
    border-bottom: 1px solid $pug-gray-disabled-bg;
    padding: 4px 16px;
  }

  .sub-line {
    display: flex;
    justify-content: space-between;
  }
}

/deep/.el-form-item__label {
  font-size: 16px;
  color: $pug-black;
  font-weight: 400;
  text-transform: none;
}

.payment-detail-box {
  width: 42.2%;
  max-width: 456px;
  font-size: 16px;
  color: $black;
  display: flex;
  flex-direction: column;

  .check-box {
    margin-top: auto;
    margin-bottom: 30px;

    .checkbox-wrapper {
      justify-content: center;
      align-items: center;
    }
  }
  .sub-title {
    font-weight: 900;
    margin-bottom: 30px;
    font-size: 18px;
    color: $black;
  }
  .detail-list {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    li {
      display: flex;

      + .hr {
        margin-top: 20px;
        border-top: solid 1px #dedede;
      }
    }
    .lable {
      font-size: 14px;
      color: #5b5b5b;
    }
    .value {
      margin-left: auto;
      font-size: 14px;
      font-weight: 500;
      color: $pug-black;
      &.minus {
        color: $pug-red;
      }
    }

    .lable.bold,
    .value.bold {
      color: $pug-black;
      font-weight: 900;
      margin-top: 20px;

      display: flex;
      align-items: center;
      justify-content: center;

      .info-popover-wrapper {
        margin-left: 8px;
      }
    }

    .value.bold {
      font-size: 20px;
    }
    .lable.bold {
      font-size: 14px;
    }
  }
  &.mobile {
    width: 100%;
    max-width: unset;

    .detail-list {
      padding: 10px;
      .value {
        text-align: right;
      }
    }

    .right-title {
      margin-top: 24px;
    }
  }
  .detail {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    &-text {
      margin-bottom: 8px;
    }
  }
  .detail-list {
    margin-bottom: 8px;

    & + .detail-list {
      margin-top: 16px;
    }

    li {
      font-size: 12px;
      color: $pug-gray-pending;
      line-height: 24px;
    }
    .lable {
      display: inline-block;
      margin-right: 4px;
    }
  }
}
.btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 48px;
}
.back-button {
  flex: 0 0 calc(50% - 24px);
  margin-right: 24px;
}
.confirm-button {
  flex: 0 0 calc(50% - 24px);
}
.action-button {
  font-size: 16px;
  width: 100%;
}

@media (min-width: 768px) {
  /deep/.payout-radio {
    .el-radio-group {
      margin-bottom: -16px;
    }
    .el-radio-button {
      margin-right: 8px;
      margin-bottom: 18px;
      width: unset;
    }
    &-item-box {
      width: 185px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .payment-method {
    &-item {
      padding: 14px 16px;
    }
  }

  .payment-detail-box {
    margin-top: 0;
    .detail-list li {
      font-size: 14px;
    }
  }

  .back-button {
    width: 200px;
    height: 60px;
    order: 1;
  }
  .confirm-button {
    width: 256px;
    height: 60px;
    order: 2;
  }
  .action-button {
    height: 60px;
  }
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: $pug-black;
  margin-bottom: 8px;
  text-transform: none;
}

@media (min-width: 842px) {
  .title {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.withdraw-container {
  padding: 16px 0;
}
</style>
