import { apiGetCpsWithdrawalChannel } from '@/resource/cps'
import { countryIsoCode } from '@/constants/countryCode'
import { apiPtGetNonCreditCardWithdrawTypeCP } from '@/resource'

export default {
  data() {
    return {
      cpsData: [],
      cryptoData: [],
      availableTypes: [],
      cryptoAvailableTypes: [80, 81, 34, 36],
      cryptoCps: [86, 87],
    }
  },
  computed: {
    getCountryIsoCode() {
      return countryIsoCode.find(c => c.id === parseInt(this.$store.state.common.countryCode)).code
    },
  },
  methods: {
    async fetchPropTradingWithdrawalMethod(accountNumber) {
      try {
        const nonCpsWithdrawalRes = await apiPtGetNonCreditCardWithdrawTypeCP({ accountNumber })
        if (nonCpsWithdrawalRes?.status === 200) {
          if (nonCpsWithdrawalRes?.data?.length) {
            nonCpsWithdrawalRes.data.forEach(item => {
              if (this.cryptoAvailableTypes.includes(item.id)) {
                this.cryptoData.push({
                  ...item,
                  label: item.nameKey.split('.').pop(),
                  sourceId: item.id,
                })
              }
            })
          }
        }

        const cpsWithdrawalRes = await apiGetCpsWithdrawalChannel({ country: this.getCountryIsoCode })
        if (cpsWithdrawalRes?.status === 200) {
          cpsWithdrawalRes.data.data.withdrawalType.channels.forEach(m => {
            let merchantVariable = JSON.parse(m.merchant_variable)
            let withdrawalList = {
              amountLimit: merchantVariable.amountLimit,
              availableCurrencies: null,
              category: 0,
              id: m.withdrawTypeId,
              cps: m,
              nameKey: merchantVariable.description_title,
              label: merchantVariable?.label || merchantVariable?.description_title,
            }

            if (merchantVariable.enable_pt) {
              this.cpsData.push(withdrawalList)
              if (this.cryptoAvailableTypes.includes(m.withdrawTypeId)) {
                this.cryptoData.push(withdrawalList)
              }
            }
          })

          this.availableTypes = [...new Map([...this.cpsData].map(m => [m.id, m])).values()]
        } else {
          this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' })
        }
      } catch (error) {
        this.$message({ message: this.$t('withdraw.default.fetchTradeAccFailed'), type: 'error' })
      }
    },
    restrictPaymentChannelCurrencies(payment) {
      // check if payment channel is under restricted list
      if (CURRENCY_RESTRICTED_CHANNELS.includes(payment.id)) {
        if (
          CURRENCY_AVAILABLE_TYPES.hasOwnProperty(this.withdrawData.currency) &&
          CURRENCY_AVAILABLE_TYPES[this.withdrawData.currency].includes(payment.id)
        ) {
          if (MULTIPLE_CURRENCY_CHANNELS.includes(payment.id)) {
            if (payment.cps.currency_code == this.withdrawData.currency) {
              this.cpsData.push(payment)
            }
            if (payment.cps.currency_code === 'USD' && this.withdrawForm.currency === 'USC') {
              this.cpsData.push(payment)
            }
          } else {
            this.cpsData.push(payment)
          }
        }
      } else {
        this.cpsData.push(payment)
      }
    },
  },
}
